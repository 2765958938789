// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
// import { initializeApp } from "firebase/app@7.0";
// import { getAnalytics } from "firebase/analytics";

export const environment = {
  production: false,
  // apiUrl: 'http://localhost:8000',
  // PUSHER_API_KEY: 'c370de797b5f03b744ff',
  // PUSHER_API_CLUSTER: 'us2',
  // firebase: {
  //   apiKey: "AIzaSyDWTdiY98XhIxVGhnnMz3WGui8n0B-T3D8",
  //   authDomain: "ericnbello-data.firebaseapp.com",
  //   projectId: "ericnbello-data",
  //   storageBucket: "ericnbello-data.appspot.com",
  //   messagingSenderId: "19178261475",
  //   appId: "1:19178261475:web:f6d2fce5097f3d6208aed6",
  //   measurementId: "G-SEZRRF1731"
  // }
};

// initializeApp(environment.firebase);
// const analytics = getAnalytics(app);

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
